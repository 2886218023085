// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class QuotationRequestService {
    index() {
        return axios.get(API_URL + 'quotation-request', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'quotation-request/'+id, { headers: authHeader() })
    }
    store(item) {
        return axios.post(API_URL + 'quotation-request', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(API_URL + 'quotation-request/'+item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'quotation-request/'+item.id, { headers: authHeader() })
    }
}

export default new QuotationRequestService()