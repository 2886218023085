// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class WorkflowService {

    index() {
        return axios.get(API_URL + 'workflow', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'workflow/'+id, { headers: authHeader() })
    }
    store(item) {
        return axios.post(API_URL + 'workflow', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(API_URL + 'workflow/'+item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'workflow/'+item.id, { headers: authHeader() })
    }
    getApprovalGroups() {
        return axios.get(API_URL + 'approval-group', { headers: authHeader() })
    }
    updateWorkflowSteps(item) {
        return axios.post(API_URL + 'workflow-order', item, { headers: authHeader() })
    }
    departments(){
        return axios.get(API_URL + 'departments', { headers: authHeader() })
    }
}

export default new WorkflowService()
