// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class RequisitionService {
    index() {
        return axios.get(API_URL + 'requisition', { headers: authHeader() })
    }
    approved_requisitions() {
        return axios.get(API_URL + 'approved-requisitions', { headers: authHeader() })
    }
    approvable_index(user) {
        return axios.get(API_URL + 'requisition-for-approver/' + user.id, { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'requisition/' + id, { headers: authHeader() })
    }
    store(item) {
        return axios.post(API_URL + 'requisition', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(API_URL + 'requisition/' + item.id, item, { headers: authHeader() })
    }
    approve(item) {
        return axios.post(API_URL + 'requisition-approve/' + item.id, item, { headers: authHeader() })
    }
    reject(item) {
        return axios.post(API_URL + 'requisition-reject/' + item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'requisition/' + item.id, { headers: authHeader() })
    }
    deleteItem(item) {
        return axios.delete(API_URL + 'requisition-item/' + item.id, { headers: authHeader() })
    }
    sendToEboard(item) {
        return axios.post(API_URL + 'requisition-send-to-eboard', item, { headers: authHeader() })
    }

}

export default new RequisitionService()